.ebo-footer {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: #f1f6ff;
  padding-block: 4rem;
  position: relative;
}
/* footer more about us section */
.footer-more-about-head {
  display: flex;
  justify-content: space-between;
  /* padding-inline: 2rem; */
}

/* footer bottom section */
.footer-bottom {
  position: absolute;
  bottom: -2rem;
  width: 100%;
  padding-block: 0.5rem;
  text-align: center;
  font-size: var(--text-size-medium);
  font-weight: 900;
}
/* footer left section */
.footer-left {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.footer-left::after {
  content: "";
  position: absolute;
  right: 0;
  height: 100%;
  border-right: 3px solid rgba(23, 30, 41, 0.452);
}
.footer-left-cont {
  display: flex;
}
.footer-left-h3 {
  font-size: var(--text-size-medium);
  margin-bottom: 1rem;
}
.footer-left-li {
  list-style: none;
}
.footer-left-li a {
  color: var(--text-color-grey);
  font-size: var(--text-size-small);
}
.footer-left-li a:hover {
  text-decoration: none;
}

/* footer right part design */
.footer-right {
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer-right-cont {
  width: 80%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.footer-right-sub-cont2-p {
  font-size: var(--text-size-small);
  color: var(--text-color-grey);
  font-weight: 600;
}
.footer-right-sub-cont2-p svg {
  font-size: var(--text-size-medium);
  color: #2136d4;
  margin-inline: 0.4rem;
  position: relative;
  top: 0.2rem;
}

.footer-right-social {
  text-align: center;
  width: 100%;
  margin-top: 0.5rem;
}
.footer-right-social svg {
  color: rgba(27, 27, 27, 0.712);
  font-size: 1.5rem;
}
.footer-right-social svg:hover {
  color: #2136d4;
  font-size: 1.5rem;
  transform: scale(1.1);
}

.footer-right-social img {
  width: 1.5rem;
  margin-top: 0.5rem;
  margin-inline: 0.2rem;
}
.footer-right-sub-cont1-p {
  font-size: var(--text-size-large);
  font-weight: 900;
}
.footer-left-i {
  display: none;
}
@media screen and (max-width: 768px) {
  .footer-bottom {
    font-size: var(--text-size-small);
  }
  .footer-left::after {
    content: none;
  }
  .ebo-footer {
    grid-template-columns: 1fr;
    gap: 2rem;
    padding-block: 5rem 2rem;
  }
  /* footer left  */
  .footer-left-cont {
    width: 90%;
    flex-direction: column;
  }
  .footer-left-categories,
  .footer-left-policies,
  .footer-left-about,
  .footer-left-cities {
    height: 2rem;
    overflow: hidden;
    transition: 0.5s all;
  }
  .footer-left-h3 {
    /* border-bottom: 1px solid rgba(139, 139, 139, 0.548); */
    display: flex;
    justify-content: space-between;
    padding-right: 0.5rem;
    padding-bottom: 0.2rem;
  }
  .footer-left-i {
    display: inline-block;
    width: 1rem;
    height: 0.6rem;
    rotate: 0;
    transition: 0.5s rotate;
  }
  /* footer right */
  .footer-right-cont {
    display: flex;
    flex-direction: column-reverse;
    gap: 2rem;
  }
  .footer-right-sub-cont1-p {
    font-size: var(--text-size-medium);
  }
  .footer-right-sub-cont1-i {
    width: 7rem;
  }

  /* drop down animation */
  .footer-left-categories-anime {
    height: 14rem;
  }
  .footer-left-categories-anime img {
    rotate: 180deg;
  }
  .footer-left-policies-anime {
    height: 10rem;
  }
  .footer-left-policies-anime img {
    rotate: 180deg;
  }
  .footer-left-about-anime {
    height: 8rem;
  }
  .footer-left-about-anime img {
    rotate: 180deg;
  }
  .footer-left-cities-anime {
    height: 8rem;
  }
  .footer-left-cities-anime img {
    rotate: 180deg;
  }
}
@media screen and (max-width: 900px) and (min-width: 768px) {
  .footer-right-sub-cont1-p {
    font-size: var(--text-size-medium);
  }
  .footer-right-sub-cont1-i {
    width: 7rem;
  }
}
